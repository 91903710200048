(function() {
  var __sections__ = {};
  (function() {
    for(var i = 0, s = document.getElementById('sections-script').getAttribute('data-sections').split(','); i < s.length; i++)
      __sections__[s[i]] = true;
    })();
    (function() {
  if (!__sections__["cart-Drawer"]) return;
  try {
    
window.SectionCartDrawer = (function(){
function SectionCartDrawer() {
  this._run();
}

SectionCartDrawer.prototype = {
  _run: function() {
    update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);

    if(!disable_cartDrawer){
      var _add_to_cart = jQuery('.jq-cart-add-product');

      _add_to_cart.submit(function () {
        $('#add-to-cart-error').html('');
        jQuery('.btn-add-tocart').prop('disabled', true);
        jQuery('.btn-add-tocart .btn-progress').animate({ width: '30%'}, 250);

        VastaShop.Cart.addItem(jQuery(this).serialize()).then(function (prod, status) {
          if ('success' == status) {
            VastaShop.Cart.getAllItems().then(function(cart, status){
              if ('success' === status) {
                jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
                    jQuery(this).css('width', '0').show();
                });

                VastaShop.Cart.current = cart;

                jQuery('.cupom-text').show();
                render_cart_drawer(VastaShop.Cart.current, jQuery('.list-products'), 0,'');

                update_shipping_bar(cart.total_price,cart.item_count);

                jQuery('.btn-add-tocart').prop('disabled', false);
                jQuery('body').addClass('cart-drawer-open cart-open');
                inventoryControl(prod.id);
              }
            }).catch(function(err) {
              console.log(err);
            });
          }
        }).catch(function(err) {
          if (err.status === 422) {
            var product_variants = $('#all-product-variants'),
                max_allowed = parseInt($('option:selected', product_variants).data('inventory')),
                variant_in_cart = VastaShop.Cart.current.items.filter(function(e){
                  return e.id == product_variants.val();
                }),
                quantity_limit_message;

            jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
              jQuery(this).css('width', '0').show();
              $('.btn-add-tocart').attr('disabled', false);
            });
            
            if (variant_in_cart.length > 0) {
              quantity_limit_message = max_allowed - variant_in_cart[0].quantity;

              if (quantity_limit_message > 0) {
                quantity_limit_message = 'You can only add ' + quantity_limit_message + (quantity_limit_message != 1 ? ' units of this product variants.': 'unit of this product variant.');
              } else {
                quantity_limit_message = 'This variant can\'t be added anymore';
              }

              $('#add-to-cart-error').html( quantity_limit_message );
            }
          } else {
            throw err;
          }
        });

        return false;
      });
    }

    $('.list-products').on('click','.cart-product-wrapper',function(){
      if($(this).find('.jq-plus-cart-item').attr('disabled') == 'disabled'){
          $(this).find('.jq-plus-cart-item').removeAttr('disabled');
      }
    });

    if (!jQuery('body').hasClass('template-cart')) {
      jQuery('.list-products').click('.jq-minus-cart-item', function (e) {
        var item = jQuery(e.target).eq(0);

        if (item.hasClass('jq-plus-cart-item')) {
            plusItem(item, '.input-qtd');
        } else if (item.hasClass('jq-minus-cart-item')) {
            minusItem(item, '.input-qtd');
        } else if (item.hasClass('bt-remove-cart')){
          removeItem('.jq-remove-cart-item', '#jq-cart-item-', '.jq-total-price', item.attr('name'), item);
          return false;
        }
      });

      jQuery('.list-products').on('submit', '.jq-qtd-item-cart', function(e){
        var data = $(this).serializeArray();
        var OlD = $(this).find('.input-qtd').prop('defaultValue');

        $('#CartDrawer form').attr('disabled', 'disabled');

        VastaShop.Cart.change(data).then(function(cart){
          VastaShop.Cart.current = cart;
          render_cart_drawer( cart, jQuery('.list-products'),OlD, data[0].value.split(':')[0] );
          inventoryControl(data[0].value.split(':')[0]);
          
          update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
        }).catch(function(err) {
            console.log(err);
        });

        return false;
      });
    }

    if (!jQuery('body').hasClass('template-cart')){
      jQuery('.list-products').on('change ', '.input-qtd', function (e) {
        var input_qtd = jQuery(this).eq(0),
            item = jQuery(e.target).eq(0);

        if(input_qtd.val() < 1){
          input_qtd.val(1);
        }

        if(input_qtd.val() >= parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.val(parseInt(input_qtd.attr('data-max')));
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }else if(input_qtd.val() < parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.parent().find('.jq-plus-cart-item').removeAttr('disabled');
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }

        jQuery('.list-products').trigger('submit');
      });
    }

    jQuery('.list-products').on('blur ', '.input-qtd', function (e) {
      jQuery(this).submit();
    })

    jQuery('.main-header .open-drawer').click(function(){
      if( !jQuery('body').hasClass('cart-drawer-open') && $(this).is('span'))
        jQuery('body').addClass('cart-drawer-open cart-open');
    });

    jQuery('.cart-drawer .fallback-text').click(function(){
      if( jQuery('body').hasClass('cart-open') )
        jQuery('body').removeClass('cart-open cart-drawer-open');
    });
  },

  onLoad: function(){
    update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
  },

  onSelect: function() {
    this._run();
  },

  onUnload: function() {
    jQuery('.main-header .open-drawer').off('click');
    jQuery('.cart-drawer .fallback-text').off('click');
    jQuery('.list-products').off('click');
  }
};

return new SectionCartDrawer;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-cart-drawer"]) return;
  try {
    
window.SectionCustomCartDrawer = (function(){
function SectionCustomCartDrawer() {
  this._run();
}

SectionCustomCartDrawer.prototype = {
  _run: function() {
    update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);

    if(!disable_cartDrawer){
      var _add_to_cart = jQuery('.jq-cart-add-product');

      _add_to_cart.submit(function () {
        if (!jQuery(this).serialize()) {
          return false;
        }

        $('#add-to-cart-error').html('');
        jQuery('.btn-add-tocart .btn-progress').animate({ width: '30%'}, 250);

        VastaShop.Cart.addItem(jQuery(this).serialize()).then(function (prod, status) {
          if ('success' == status) {
            VastaShop.Cart.getAllItems().then(function(cart, status){
              if ('success' === status) {
                jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
                    jQuery(this).css('width', '0').show();
                });

                VastaShop.Cart.current = cart;

                jQuery('.cupom-text').show();
                render_cart_drawer(VastaShop.Cart.current, jQuery('.list-products'), 0,'');

                update_shipping_bar(cart.total_price,cart.item_count);

                jQuery('.btn-add-tocart').prop('disabled', false);
                jQuery('body').addClass('cart-drawer-open cart-open');
                inventoryControl(prod.id);
              }
            }).catch(function(err) {
              console.log(err);
            });
          }
        }).catch(function(err) {
          if (err.status === 422) {
            var product_variants = $('#all-product-variants'),
                max_allowed = parseInt($('option:selected', product_variants).data('inventory')),
                variant_in_cart = VastaShop.Cart.current.items.filter(function(e){
                  return e.id == product_variants.val();
                }),
                quantity_limit_message;

            jQuery('.btn-add-tocart .btn-progress').animate({ width: '100%'}, 250, function(){
              jQuery(this).css('width', '0').show();
              $('.btn-add-tocart').attr('disabled', false);
            });
            
            if (variant_in_cart.length > 0) {
              quantity_limit_message = max_allowed - variant_in_cart[0].quantity;

              if (quantity_limit_message > 0) {
                quantity_limit_message = 'You can only add ' + quantity_limit_message + (quantity_limit_message != 1 ? ' units of this product variants.': 'unit of this product variant.');
              } else {
                quantity_limit_message = 'This variant can\'t be added anymore';
              }

              $('#add-to-cart-error').html( quantity_limit_message );
            }
          } else {
            throw err;
          }
        });

        return false;
      });
    }

    $('.list-products').on('click','.cart-product-wrapper',function(){
      if($(this).find('.jq-plus-cart-item').attr('disabled') == 'disabled'){
          $(this).find('.jq-plus-cart-item').removeAttr('disabled');
      }
    });

    if (!jQuery('body').hasClass('template-cart')) {
      jQuery('.list-products').click('.jq-minus-cart-item', function (e) {
        var item = jQuery(e.target).eq(0);

        if (item.hasClass('jq-plus-cart-item')) {
            plusItem(item, '.input-qtd');
        } else if (item.hasClass('jq-minus-cart-item')) {
            minusItem(item, '.input-qtd');
        } else if (item.hasClass('bt-remove-cart')){

          removeItem('.jq-remove-cart-item', '#jq-cart-item-', '.jq-total-price', item.attr('name'), item);
	setTimeout(function(){

              jQuery('#upsell-produce-atc-' + item.attr('name').replace(/\:(.*)/gi, '')).attr('disabled', false);
			  jQuery('option[value="' + item.attr('name').replace(/\:(.*)/gi, '') + '"]').attr('disabled', false);
			  jQuery('.upsell-product-' + item.attr('name').replace(/\:(.*)/gi, '')).prop('checked', false);	




			  $('#get-product-priority').prop('checked', false);

			  $('option[value="' + item.attr('name').replace(/\:(.*)/gi, '') + '"]').attr('disabled', false).parent().attr('disabled', false);

			  $('option[value="' + item.attr('name').replace(/\:(.*)/gi, '') + '"]').attr('disabled', false).closest('form').find('button').attr('disabled', false);
			
 			
			  updateATCMoney();


              VastaShop.Cart.getAllItems().then(function(cart){
                  VastaShop.Cart.current = cart;

                  var mainItems = VastaShop.Cart.current.items.filter(i => {
                      return !(i.properties && i.properties.Special);
                  });

                  if (mainItems.length == 0) {
                      VastaShop.Cart.clear().then(function(cart){
                          VastaShop.Cart.current = cart;

                          
                          jQuery('.drawer-title').html(empty_cart());
                          jQuery('.list-products').html(continue_shopping());
                          jQuery('.cart-container').addClass('empty');
                          jQuery('.cart-products-wrapper').removeClass('product-content');
                          jQuery('.cupom-text').hide();
                          jQuery('.count').html(total_items(cart));
						  jQuery('.upsell-produce-form').prop('disabled', false);
						  jQuery('.upsell-produce-atc, .upsell-produce-form select, .upsell-produce-form select option').prop('disabled', false);
                      });
                  }
              });
			}, 2000);
          return false;
        }
      });

      jQuery('.list-products').on('submit', '.jq-qtd-item-cart', function(e){
        var data = $(this).serializeArray();
        var OlD = $(this).find('.input-qtd').prop('defaultValue');

        $('#CartDrawer form').attr('disabled', 'disabled');

        VastaShop.Cart.change(data).then(function(cart){
          VastaShop.Cart.current = cart;
          render_cart_drawer( cart, jQuery('.list-products'),OlD, data[0].value.split(':')[0] );
          inventoryControl(data[0].value.split(':')[0]);
          
          update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
        }).catch(function(err) {
            console.log(err);
        });

        return false;
      });
    }

    if (!jQuery('body').hasClass('template-cart')){
      jQuery('.list-products').on('change ', '.input-qtd', function (e) {
        var input_qtd = jQuery(this).eq(0),
            item = jQuery(e.target).eq(0);

        if(input_qtd.val() < 1){
          input_qtd.val(1);
        }

        if(input_qtd.val() >= parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.val(parseInt(input_qtd.attr('data-max')));
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }else if(input_qtd.val() < parseInt(input_qtd.attr('data-max')) && parseInt(input_qtd.attr('data-max')) != -1){
          input_qtd.parent().find('.jq-plus-cart-item').removeAttr('disabled');
          input_qtd.parent().find('.jq-minus-cart-item').removeAttr('disabled');
        }

        jQuery('.list-products').trigger('submit');
      });
    }

    jQuery('.list-products').on('blur ', '.input-qtd', function (e) {
      jQuery(this).submit();
    })

    jQuery('.main-header .open-drawer').click(function(){
      if( !jQuery('body').hasClass('cart-drawer-open') && $(this).is('span')) {
        groupBundleProducts();
        jQuery('body').addClass('cart-drawer-open cart-open');
      }
    });

    jQuery('.cart-drawer .fallback-text').click(function(){
      if( jQuery('body').hasClass('cart-open') )
        jQuery('body').removeClass('cart-open cart-drawer-open');
    });
                                                           

      // 
      // PODUCT PRIORITY PROCESSING
      // 
      var _add_to_cart = jQuery('.btn-add-tocart > .btn-money'),
        _upsell = jQuery('#upsell_cart_drawer'),
        upsell_price = parseInt(_upsell.data('price')),
        quantity = parseInt(jQuery('#ProductQuantity').val()),
        price = parseInt(jQuery('.product-variant[name="id"] option:selected').data('price'));
   
      _upsell.click(function () {
        if (_upsell.is(':checked')) {
          _add_to_cart.text(Shopify.formatMoney(price * parseInt(jQuery('#ProductQuantity').val()) + upsell_price));
        } else {
          _add_to_cart.text(Shopify.formatMoney(price * quantity));
        }
      });

      _upsell.change(function () {
        addUpsell(jQuery(this).val(), jQuery(this).is(':checked') ? 'add' : 'updt');
      });

      function addUpsell(id, opr) {
        if (opr == 'add') {
          jQuery.ajax({
            url: '/cart/add.js',
            method: 'post',
            dataType: 'json',
            data: {
              quantity: 1,
              id: id,
              properties: {
                'Special': 'Add-On'
              }
            }
          }).then(function (prod) {
			updateATCMoney(prod.price);
            addonAfterAdded(prod);
            upsell_updateCart(VastaShop.Cart.current);
          });
        } else if (opr == 'updt') {
          $.ajax({
            url: '/cart/update.js',
            method: 'post',
            dataType: 'json',
            data: "updates[" + id + "]=0"
          }).then(function (cart) {
            if (typeof addonAfterRemoved === 'function') {
			  updateATCMoney();
              addonAfterRemoved(cart);
              upsell_updateCart(cart);
            }
          });
        }
      }


      function upsell_updateCart(cart) {
        VastaShop.Cart.current = cart;

        update_btn_add(cart.item_count);
        render_cart_drawer(cart, jQuery('.list-products'));
        update_shipping_bar(cart.total_price, cart.item_count);

        if (cart.item_count < 1) {
          jQuery('.drawer-title').html(empty_cart());
          jQuery('.list-products').html(continue_shopping());
          jQuery('.cart-container').addClass('empty');
          jQuery('.cart-products-wrapper').removeClass('product-content');
          jQuery('.cupom-text').hide();
          jQuery('.count').html(total_items(cart));
        } else if (cart.item_count == 1) {
          jQuery('.jq_qtd_bt_proceed').html(total_items(cart) + ' Item');
          jQuery('.count').html(total_items(cart));
          jQuery('.cupom-text').show();
        } else if (cart.item_count > 1) {
          jQuery('.cupom-text').show();
          jQuery('.jq_qtd_bt_proceed').html(total_items(cart) + ' Items');
          jQuery('.count').html(total_items(cart));
        }
      }
    },

    onLoad: function () {
      update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
    },

    onSelect: function () {
      this._run();
    },

    onUnload: function () {
      jQuery('.main-header .open-drawer').off('click');
      jQuery('.cart-drawer .fallback-text').off('click');
      jQuery('.list-products').off('click');
    }
  };
  
return new SectionCustomCartDrawer;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["custom-product-video"] && !window.DesignMode) return;
  try {
    
window.addEventListener('DOMContentLoaded', function(){
  if ($(".dynamic-video").length > 0) {
    $(".dynamic-video-wrapper").slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: false,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev custom-arrow-prev"><span>⟨</span></button>',
      nextArrow: '<button type="button" class="slick-next custom-arrow-next"><span>⟩</span></button>'
    });
  }
});

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["footer"]) return;
  try {
    
window.SectionFooter = (function(){
  function SectionFooter() {
    this._run();
  }

  SectionFooter.prototype = {
    _run: function() {
      if ( $(window).width() < 768 )
        $('.site-footer .menu-footer-grid').addClass('clickable');
      else
		    $('.site-footer .menu-footer-grid').removeClass('clickable');

		$(window).resize(function(){
      if ( $(window).width() < 768 ) {
        $('.site-footer .menu-footer-grid').addClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').hide();
      } else {
        $('.site-footer .menu-footer-grid').removeClass('clickable');
        $('.site-footer .menu-footer-grid .no-bullets').show();
		  }
		});

		$('.site-footer .menu-footer-grid').click(function(){
		  	if ($(window).width() < 768 && $(this).hasClass('clickable')) {

          $('.site-footer .menu-footer-grid .no-bullets').stop().slideUp();

          if ($('.no-bullets', this).is(':visible')){
            $('.no-bullets', this).stop().slideUp();
            $('.nav-title', this).removeClass('row-rotate');
          }else {
            $('.nav-title', this).addClass('row-rotate');
            $('.no-bullets', this).stop().slideDown();
            $(this).siblings().children('.nav-title').removeClass('row-rotate');
        }
      }
      });
    },

    onLoad: function() {
      this._run();
    },

    onUnload: function() {

    }
  };

  return new SectionFooter;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["header"]) return;
  try {
    
window.SectionHeader = (function(){
  function SectionHeader() {
    this._run();
  }

  SectionHeader.prototype = {
    _run: function() {
      $(document).on('click', '.main-header .cart-icon', function(){
        if( !$('body').hasClass('cart-drawer-open') )
          $('body').addClass('cart-drawer-open cart-open');
      });

      $(document).on('click', '.cart-drawer .fallback-text', function(){
        if( $('body').hasClass('cart-open') )
          $('body').removeClass('cart-open cart-drawer-open');
      });

      $(document).on('click', '.DrawerOverlay', function(){
        $('body').removeClass('js-drawer-open-left cart-open cart-drawer-open');
      });
    },

    onSelect: function() {
      this._run();
    },

    onUnload: function() {
      $('.main-header .cart-icon').off('click');
      $('.cart-drawer .fallback-text').off('click');
      $('.DrawerOverlay').off('click');
    }
  };

  return new SectionHeader;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["instafeed-theme"] && !window.DesignMode) return;
  try {
    
function formatInstagramDate( image ) {
    var monthNames,
        date = new Date(image.created_time * 1000);

    m = date.getMonth();
    d = date.getDate();
    y = date.getFullYear();

    monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    image.created_time = monthNames[m] + ' ' + d + ', ' + y;

    return true;
}

window.SectionInstafeed = (function(){
    function SectionInstafeed() {
        this.section = null;
        this.data = null;

        this._run();
    }

    function getResolution( data ) {
        return (jQuery(window).width() < 767 || data.image_per_row > 4) ? 'low_resolution' : 'standard_resolution';
    }

    function newInstafeed( data, resolution, limitphoto ) {
        return new Instafeed({
            get: 'user',
            userId: 'self',
            accessToken: data.access_token,
            sortBy: 'most-recent',
            limit: limitphoto,
            resolution: resolution,
            template: data.template,

            after: function() {
                var images = $('.instagram__image-wrapper');
    
                images.filter(':nth-child(2n + 1)').addClass('grid__item--mobile-first');
                images.filter(':nth-child(' + data.image_per_row + 'n + 1)').addClass('grid__item--tablet-first grid__item--desktop-first');
            },
            filter: $.proxy(formatInstagramDate)
        });
    }

    function runInstafeed( data ) {
        var resolution, limitphoto;

        if (data.access_token) {
            resolution = getResolution( data ) ;

            if(jQuery(window).width() < 767) {
                limitphoto = 6;
            } else {
                limitphoto = data.image_per_row * data.rows;

                var feed = newInstafeed( data, resolution, limitphoto );

                console.log('<<<<', feed);
            
                feed.run();
            }
        }
    }

    SectionInstafeed.prototype = {
        _run: function() {
            this.section = document.querySelector('[data-section-type="instafeed"]');

            if (this.section) {
                this.data = {
                    access_token: this.section.getAttribute('data-access-token'),
                    rows: this.section.getAttribute('data-rows'),
                    image_per_row: this.section.getAttribute('data-image-per-row'),
                    template: '<div class="instagram__image-wrapper"><a href="{{link}}" id="{{id}}" target="_blank"><div class="instagram__overlay"><p class="instagram__caption">{{caption}}</p><time class="instagram__date">{{model.created_time}}</time></div><img class="instagram__image lazy" src="{{image}}" data-src="{{image}}"/></a></div>'
                };

                runInstafeed( this.data );
            }
        },

        onLoad: function() {
            this._run();
        },
    };

    return new SectionInstafeed;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["menu-mobile"]) return;
  try {
    
$(document).on('click', '.jq-icon-fallback-text', function(){
  if($('body').hasClass('js-drawer-open-left'))
    $('body').removeClass('js-drawer-open-left');
  else {
    $('.main-content').addClass('is-moved-by-drawer');
    $('.fixed').addClass('is-moved-by-drawer');
    $('.shopify-section header').addClass('is-moved-by-drawer');
    $('.search').addClass('is-moved-by-drawer');
    $('body').addClass('js-drawer-open-left');
  }
});

$(document).on('click', '.DrawerOverlay', function(){
  $('body').removeClass('js-drawer-open-left cart-open cart-drawer-open');
});

$(document).on('click', '.mobile-nav__toggle-open,.mobile-nav__link[href^="#"]', function(){
  var instance = $(this);

  instance.parent().siblings('.child').slideToggle(400);

  if (instance.is('.mobile-nav__link')) {
    instance = instance.siblings('.mobile-nav__toggle-open');;
  }

  if( instance.hasClass('anime-plus-ver') ) {
    instance.removeClass('anime-plus-ver anime-plus-hor');
  } else {
    instance.addClass('anime-plus-ver anime-plus-hor');  
  }
});

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["product-tabs"]) return;
  try {
    
(function($){
    var $document = $(document);
    $document.ready(function(){
        $document.on('click', '.tabs', function(){
            var tabs = $(this),
                scope = tabs.parent().attr('data-tabs-scope');
            tabs.addClass('active').siblings().removeClass('active');
            $('.tabs-content[data-tabs-scope="' + scope + '"] .tab-content[data-identifier="' + tabs.attr('data-target') + '"]').addClass('active').siblings().removeClass('active');
            return false;
        });
    });
})(jQuery);

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["recently-viewed-products"]) return;
  try {
    
window.SectionRecentlyViewedProducts = (function(){
    function show_recently_viewed_products2(e, settings) {

        if (window.location.pathname.indexOf(e.url) < 0 && (typeof product_template === 'function')) {
            $('#RecentlyViewedProducts').prepend(product_template( e ));
            $('#RecentlyViewedProducts').siblings().show();
        }
    }

    function SectionRecentlyViewedProducts() {
        this._run();
    }
  
    SectionRecentlyViewedProducts.prototype = {
        _run: function() {
            VastaShop.RecentlyViewedProducts.setup({
                show_price: VastaShop.Variables.is_template_index
            });

            VastaShop.RecentlyViewedProducts.register( VastaShop.Product.current );

            VastaShop.RecentlyViewedProducts.show( show_recently_viewed_products2 );
        },

        onLoad: function() {
            this._run();
        },
    
        onUnload: function() {
            
        }
    };
  
    return new SectionRecentlyViewedProducts;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["reviews"] && !window.DesignMode) return;
  try {
    
window.SectionReviews = (function(){
  function SectionReviews() {
    this.settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
      responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1019,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1
            }
          },
          {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
          }
      ]
    };

    this._run();
  }

  SectionReviews.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider, autoplay, autoplaySpeed,
          settings = this.settings;

      jQuery('[id^="slider-reviews-"]').each(function(){
        slider = '#slider-' + $(this).data('slider');

        autoplay = jQuery(slider).data('autoplay');
        autoplaySpeed = jQuery(slider).data('speed');

        if (typeof autoplay !== 'undefined' && autoplay != true && autoplay != false) {
          autoplay = true;
        }

        if (typeof autoplaySpeed !== 'undefined' && autoplaySpeed < 800 && autoplaySpeed > 3000) {
          autoplaySpeed = 800;
        }

        settings.autoplay = autoplay;
        settings.autoplaySpeed = autoplaySpeed;

        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionReviews;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["shipping_bar"]) return;
  try {
    
  window.SectionShippingBar = (function(){
    function SectionShippingBar() {
      this._run();
    }
  
    SectionShippingBar.prototype = {
      _run: function() {
        update_shipping_bar(VastaShop.Cart.current.total_price, VastaShop.Cart.current.item_count);
      },
  
      onSelect: function() {
        this._run();
      },
  
      onUnload: function() {
  
      }
    };
  
    return new SectionShippingBar;
    })();
  
  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["slider-promotional"] && !window.DesignMode) return;
  try {
    
window.SectionSliderPromotional = (function(){
  function SectionSliderPromotional() {
    this.settings = {
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      prevArrow: '<button type="button" class="slick-prev">&lang;</button>',
      nextArrow: '<button type="button" class="slick-next">&rang;</button>',
      arrows: true,
      responsive: [
          {
              breakpoint: 1180,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              }
          },
          {
              breakpoint: 1019,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              }
          },
          {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
          }
      ]
    };

    this._run();
  }

  SectionSliderPromotional.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider, autoplay, autoplaySpeed, settings = this.settings;

      jQuery('[id^="slider-promotional-"]').each(function(){
        slider = '#promotional-' + $(this).data('slider');
        
        autoplay = jQuery(slider).data('autoplay');
        autoplaySpeed = jQuery(slider).data('speed');

        if (typeof autoplay !== 'undefined' && autoplay != true && autoplay != false) {
          autoplay = true;
        }

        if (typeof autoplaySpeed !== 'undefined' && autoplaySpeed < 800 && autoplaySpeed > 3000) {
          autoplaySpeed = 800;
        }

        settings.autoplay = autoplay;
        settings.autoplaySpeed = autoplaySpeed;
        
        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionSliderPromotional;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["swatch"]) return;
  try {
    
window.SectionSwatch = (function(){
    /**
    * @param variants
    */
    function prepare_variants(variants) {
        var variations = [];

        variants.each(function () {
            variations.push($(this).val());
        });

        return variations;
    }

    /**
    *
    */
    function get_variation(all_variants, options) {
        var variations = [];

        options = options.join(',');
        variations = $(all_variants).find('option[data-options="' + options + '"]');

        if (variations.length > 0)
            $(all_variants).val(variations.val()).trigger('change');
    }

    /**
    * Check this function
    */
    function filter_variants(Product, options, option_current, swatch_index, enable_atc_button) {
        var swatch_type = $('.swatch-type[data-index="' + (swatch_index + 1) + '"]');

        if (typeof Product.variations[option_current] !== 'undefined'){
            change_swatch_variants( swatch_type, Object.keys(Product.variations[option_current]), enable_atc_button );
        }else if (typeof Product.variations[options[0]] !== 'undefined' && typeof Product.variations[options[0]][option_current] !== 'undefined'){
            change_swatch_variants( swatch_type, Product.variations[options[0]][option_current], enable_atc_button );
        }
    }

    /**
    *
    */
    function change_swatch_variants(swatch, variants, enable_atc_button) {
        var first_select_value,
            swatch_select = swatch.find('.product-variant option'),
            swatch_radio  = swatch.find('.swatch-variant-value');

        $('.product-variant', swatch).prop('disabled', false);
        swatch_radio.parent().addClass('swatch-disabled');

        // BEGIN_TAG_SELECT
            swatch_select.prop({
                disabled: true, 
                checked: false
            });

            swatch_select.each(function(){
                if (variants.indexOf($(this).val()) > -1) {
                    $(this).prop('disabled', false);
                }
            });

            first_select_value = swatch_select.filter(':not(:disabled):first').val();

            if (enable_atc_button)
                swatch_select.parent().val(first_select_value).trigger('change');
        // END_TAG_SELECT

        // BEGIN_RADIO_BUTTON
            swatch_radio.prop({
                disabled: true,
                checked: false
            });

            swatch_radio.each(function(i){
                if (variants.indexOf($(this).val()) > -1) {
                    $(this).prop('disabled', false);
                    $(this).parent().removeClass('swatch-disabled');
                }
            });

            if (enable_atc_button)
                swatch_radio.filter(':not(:disabled):first').trigger('click');
        // END_RADIO_BUTTON

        swatch.prev().find('.choose-option').removeClass('choose-option');
    }

    function SectionSwatch() {
        this._run();
    }

    function choose_before_option( i ) {
        $('.swatch-type[data-index="'+ i +'"] .swatch-element').on('click', function(){
            var swatch_checkeds = $('.swatch-type[data-index="'+ (i - 1) +'"] input:checked').length;
            var swatch_selects = typeof $('.swatch-type[data-index="'+ (i - 1) +'"] select').val() !== 'undefined' && $('.swatch-type[data-index="'+ (i - 1) +'"] select').val() !== '';
            
            $('.swatch-type.swatch-elements-wrapper').removeClass('choose-option');

            if (swatch_checkeds === 0 && swatch_selects === false) {
                $('.swatch-type[data-index="'+ (i - 1) +'"] .swatch-elements-wrapper').addClass('choose-option');
            }
        });
    }

    SectionSwatch.prototype = {
        _run: function() {
            var _quantity = $('.product-quantity-wrapper > .quantity'),
                _product_featured = $('.jq-featured-slider'),
                _add_to_cart_value = $('.btn-add-tocart > .btn-money'),
                _product_options = $('.jq-swatch-element,.jq-product-variant'),
                _current_price = $('.product-price .current-price'),
                _current_compare_price = $('.product-price .compare-price'),
                _choosed_variant = $('.all-variant'),
                _price = $(':selected', _choosed_variant).attr('data-price'),
                _global_variant_compare_at_price = $(':selected', $('.all-variant')).attr('data-compare-at-price') === "" ? "" : Shopify.formatMoney($(':selected', $('.all-variant')).attr('data-compare-at-price'));


            $('.swatch-type[data-index="1"] .swatch-element').each(function(){
                if (Object.keys(VastaShop.Product.current.variations).indexOf($(this).attr('data-swatch')) > -1) {
                    $(this).removeClass('swatch-disabled').find('input').prop('disabled', false);
                } else {
                    $(this).find(".tooltip").remove();
                    $(this).append($('<span class="tooltip">Out Of Stock</span>'));
                }
            });

            $('.swatch-type[data-index="1"] .jq-product-variant option').each(function(){
                if (Object.keys(VastaShop.Product.current.variations).indexOf($(this).attr('value')) < 0 && $(this).attr('value') !== '') {
                    $(this).prop('disabled', true);
                }
            });

            // Product Options
            _product_options.change(function(){
                var options = prepare_variants($('.jq-product-variant option:selected,.swatch-variant-value:checked'));

                get_variation(_choosed_variant, options);
                
                $(this).parent().removeClass('choose-option');

                // Filter Variants
                filter_variants(VastaShop.Product.current, options, $(this).val(), parseInt($(this).attr('data-swatch-index')), (options.length == VastaShop.Product.current.options.length) );

                if (options.length === VastaShop.Product.current.options.length) {
                    $('.btn-add-tocart').prop('disabled', false);
                    $('.product-form-buttons-wrapper,.btn-add-tocart').removeClass('hide');
                    $('.btn-choose-variant').addClass('hide');
                    enableInputs($('.all-variant option:selected').val());
                    if(parseInt(VastaShop.Variables._input_qtd.val()) == 1){
                        VastaShop.Variables._bt_minus.attr('disabled','disabled');
                    }
                }

                return false;
            });

            if (VastaShop.Product.options.length > 0) {
                VastaShop.Product.options.forEach(function(option, i){
                    $('.swatch-element[data-swatch="' + option + '"]').trigger('click');
                    $('.product-variant option[value="' + option + '"]').parent().val(option).trigger('change');
                });
            }



            // Choosed Variant
            _choosed_variant.change(function() {
                var variant_id = $(':selected', this).val();
                var variant_image = $(':selected', this).attr('data-image');
                var variant_price = $(':selected', this).attr('data-price');
                var variant_inventory = parseInt($(':selected', this).attr('data-inventory'));
                var variant_compare_at_price = $(':selected', this).attr('data-compare-at-price') === "" ? "" : Shopify.formatMoney($(':selected', this).attr('data-compare-at-price')) ;

                $('.slick-slide:not(.slick-cloned) img').each(function(){
                  
                    var slick_image = $(this),
                        slick_index = slick_image.parent().parent().parent().parent().attr('data-slick-index');
                    if (slick_image.parent().attr('data-src') == variant_image) { 
                        _product_featured.slick('slickGoTo', slick_index);
                    }
                });

                _current_price.html(Shopify.formatMoney(variant_price));
                _current_compare_price.html(variant_compare_at_price);

                _price = $(':selected', _choosed_variant).attr('data-price');
                _add_to_cart_value.html(Shopify.formatMoney(parseInt(_quantity.val()) * _price));

                //_quantity.attr('max', variant_inventory);
                inventoryControl(variant_id);
                if (_quantity.val() > variant_inventory ){
                    _quantity.val( 1 );
                }

                enableInputs(variant_id);

                $('#upsell').removeAttr('disabled');

                if (parseInt(_quantity.val()) == 1 ){
                    _quantity.siblings('.btn-minus').attr('disabled','disabled');

                }

                _upsell = $('#upsell'),
                upsell_price = parseInt(_upsell.data('price')),
                quantity = parseInt($('#ProductQuantity').val()),
                price    = parseInt($('.product-variant[name="id"] option:selected').data('price'));


                if (_upsell.is(':checked')){
                    _add_to_cart_value.text(Shopify.formatMoney(price * parseInt($('#ProductQuantity').val()) + upsell_price));
                }else{
                    _add_to_cart_value.text(Shopify.formatMoney(price * quantity));
                }

            });

            if (prepare_variants($('.swatch-variant-value:checked', $('.swatch-element'))).length == VastaShop.Product.current.options.length) {
                _current_price.html(Shopify.formatMoney($(':selected', _choosed_variant).attr('data-price')));
                _current_compare_price.html(_global_variant_compare_at_price);

                $('#upsell').removeAttr('disabled');

                _add_to_cart_value.html(Shopify.formatMoney(parseInt(_quantity.val()) * _price));
            }

            $('.size-chart-text').click(function(){
                var link = $(this).attr('href');
                var id_modal = $(this).attr('data-modal');
                var modal = $('.jq-sizechart-modal[data-id="' + id_modal + '"]');

                modal.addClass('active');

                return false;
            });

            $('.modal-close').click(function(){
                $('.modal-container').removeClass('active');
            });

            $(window).click(function(e){
                if (e.target == $('.modal-container').get(0))
                $('.modal-container').removeClass('active');
            });

            for (var i = 2, j = VastaShop.Product.current.options.length+1; i < j; i++) {
                choose_before_option( i );
            }
        },

        onSelect: function() {
            this._run();
        }
    };

    return new SectionSwatch;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["upsell"]) return;
  try {
    
window.SectionUpsell = (function(){
  function SectionUpsell() {
    this._run();
  }

  SectionUpsell.prototype = {
    _run: function() {
      var _add_to_cart = jQuery('.btn-add-tocart > .btn-money'),
          _upsell = jQuery('#upsell'),
          upsell_price = parseInt(_upsell.data('price')),
          quantity = parseInt(jQuery('#ProductQuantity').val()),
          price    = parseInt(jQuery('.product-variant[name="id"] option:selected').data('price'));

      _upsell.click(function(){
        if (_upsell.is(':checked'))   {
            _add_to_cart.text(Shopify.formatMoney(price * parseInt(jQuery('#ProductQuantity').val()) + upsell_price));
        }else{
          _add_to_cart.text(Shopify.formatMoney(price * quantity));
        }
      });

      _upsell.change(function(){
        var data = {
          quantity: (jQuery(this).is(':checked')) ? 1 : 0,
          id: jQuery(this).val()
        };
        addUpsell(data);
      });

      function addUpsell(data) {
        VastaShop.Cart.update('updates['+ data.id +']=' + data.quantity).then(function(cart,r){
          if ('success' === r) {
            VastaShop.Cart.current = cart;

            update_btn_add(cart.item_count);
            render_cart_drawer(cart, jQuery('.list-products'));
            update_shipping_bar(cart.total_price,cart.item_count);

            if (cart.item_count < 1) {
              jQuery('.drawer-title').html(empty_cart());
              jQuery('.list-products').html(continue_shopping());
              jQuery('.cart-container').addClass('empty');
              jQuery('.cart-products-wrapper').removeClass('product-content');
              jQuery('.cupom-text').hide();
              jQuery('.count').html(total_items(cart));
            } else if (cart.item_count == 1) {
              jQuery('.jq_qtd_bt_proceed').html(total_items(cart) + ' Item');
              jQuery('.count').html(total_items(cart));
              jQuery('.cupom-text').show();
            } else if (cart.item_count > 1) {
              jQuery('.cupom-text').show();
              jQuery('.jq_qtd_bt_proceed').html(total_items(cart) + ' Items');
              jQuery('.count').html(total_items(cart));
            }
          }
        });
      }

      jQuery(document).ready(function(){
          if(always_checked){
            var data = {
              quantity: 1,
              id: _upsell.val()
            };

            addUpsell(data);
          }
      });

      jQuery('.upsell-text a').click(function( e ){
        var link = jQuery(this).attr('href');

        e.preventDefault();

        jQuery('.jq-upsell-modal').addClass('active');

        return false;
      });

      jQuery('.modal-close').click(function(){
        jQuery('.jq-upsell-modal').removeClass('active');
      });

      jQuery(window).click(function(e){
        if (e.target == jQuery('.jq-upsell-modal').get(0))
          jQuery('.jq-upsell-modal').removeClass('active');
      });
    },

    onSelect: function() {
      this._run();
    },

    onUnload: function() {
      jQuery('#upsell').off('change');
      jQuery('.upsell-text a').off('click');
    }
  };

  return new SectionUpsell;
})();

  } catch(e) { console.error(e); }
})();

(function() {
  if (!__sections__["videos"] && !window.DesignMode) return;
  try {
    

window.SectionVideoSlider = (function(){
  function SectionVideoSlider() {
    this.settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev">&lang;</button>',
      nextArrow: '<button type="button" class="slick-next">&rang;</button>',
      infinite: true
    };

    this._run();
  }

  SectionVideoSlider.prototype = {
    onLoad: function() {
      this._run();
    },

    _run: function() {
      var slider,
          settings = this.settings;

      jQuery('[id^="video-slider-"]').each(function(){
        slider = '#slider-' + $(this).data('slider');

        jQuery(slider).not('.slick-initialized').slick( settings );
      });
    }
  };

  return new SectionVideoSlider;
})();


  } catch(e) { console.error(e); }
})();
})();
